import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
//导入router目录下的router.js
import routes from './router'
 
const router = createRouter({
    //路由模式
    history: createWebHashHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    /* 页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }
    /* 判断该路由是否需要登录权限 */
    if (to.matched.some(record => record.meta.requireAuth)) {

    }
    next();
})

//导出
export default router