import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import axios from 'axios'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import vue3GoogleLogin from 'vue3-google-login'

import VueI18n from "vue3-i18n";

import messages from './locales/all.js';

// axios.defaults.baseURL = process.env.VUE_APP_BASE_URL || 'http://localhost:40007/faceswap';
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
// axios.defaults.baseURL = 'http://localhost:40007/faceswap';

// 如果您正在使用CDN引入，请删除下面一行。
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import VConsole from 'vconsole'

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.use(vue3GoogleLogin, {
  clientId: '143981696659-8djm06bi24m6jk5sokfvdc958sg0fdts.apps.googleusercontent.com'
})

app.config.globalProperties.$messages = messages;

app.use(router).use(VueI18n).use(ElementPlus, {locale: zhCn}).mount('#app')